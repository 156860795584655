/**
  Post compoent
  Component ini digunakan untuk menampilkan 1 buah card post pada blog. tidak untuk render 1 post full
 */

import React from "react"
import { Link } from "gatsby"
import { Card, CardTitle, CardText, CardSubtitle, CardBody, Badge } from 'reactstrap'
import Img from 'gatsby-image'
import { slugify } from '../util/utilityFunctions'

// destructuring.
const Post = ({title, author, slug, date, body, fluid, tags }) => {
  return (
    <Card>
      <Link to={slug}>
        <Img className="Card-image-top" fluid={fluid} />
      </Link>
      <CardBody>
        <CardTitle>
          <h2><Link to={slug} style={{color: '#424242'}}>{title}</Link></h2>
        </CardTitle>
        <CardSubtitle style={{color: '#000000'}}>
          <strong>{date}</strong> by{' '}
          <strong>{author}</strong>         
        </CardSubtitle>
        <CardText>{body}</CardText>
        <ul className="post-tags">
          {tags.map(tag => (
            <li key={tag}>
              <Link to={`/tag/${slugify(tag)}`}>
                <Badge color="dark" className="text-uppercase">{tag}</Badge>
              </Link>
            </li>
          ))}
        </ul> 

        <Link to={slug} className="btn btn-outline-dark float-right">Baca Lebih Banyak</Link>
      </CardBody>
    </Card>
  )
}

export default Post
