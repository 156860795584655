import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Post from '../components/Post'
import { Row, Col, Container } from 'reactstrap';
import Jumbotron from '../components/Jumbotron'
import SEO from "../components/seo"


const tagPosts = ({ data, pageContext }) => {
	const { tag } = pageContext
	const { totalCount } = data.allMarkdownRemark
	const pageHeader = `${totalCount} postingan dengan label "${tag}"`

	return (
		<Layout pageTitle={pageHeader}>
			<SEO 
			  title={`Label ${tag}`} 
		      lang="id"
		      img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2Fkisspng-documentation-organization-logo-service-forms-5b112049d36aa6.562040021527849033866.png?alt=media&token=5c95988e-6aa7-414e-bf99-e8bde2d0a340"
		      description="Label postingan Waingapu Open Mind"      
			/>
		    <Jumbotron title="Label Artikel Di Waingapu Open Mind" info="Kumpulan label dari artikel yang dituliskan oleh anak-anak di Waingapu Open Mind yang suka berbagi informasi." />
			
			<Container>
				<h1 className="mb-5 pt-5 text-center">{pageHeader}</h1>
				<Row>
					{data.allMarkdownRemark.edges.map(({node}) => (
						<Col md="4">
							<Post key={node.id}
								slug={node.fields.slug}
								title={node.frontmatter.title}
								author={node.frontmatter.author}
								date={node.frontmatter.date}
								body={node.excerpt}
								tags={node.frontmatter.tags}
								fluid={node.frontmatter.image.childImageSharp.fluid}
							/>
						</Col>
					))}
				</Row>

			</Container>
 		</Layout>
	)
}

// query graphql untuk melakuakn fetch graphql untuk mendapatkan daftar postingan berdasarkan tag
export const tagQuery = graphql`
	query($tag: String!) {
		allMarkdownRemark(
			sort: { fields: [frontmatter___postNumber], order: ASC }
			filter: { frontmatter: { tags: { in: [$tag]}}}
		){
			totalCount
			edges{
				node{
					id
					frontmatter{
						title
						date
						author
						tags
						image{
							childImageSharp{
								fluid(maxWidth: 650, maxHeight: 371){
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					fields {
						slug
					}
					excerpt
				}
			}
		}
	}
`

export default tagPosts